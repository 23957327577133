import axios from "axios";
import {sorter, userService} from "@/services";
import {authHeader, vue} from "@/auth";
import {openSocket, socketOn} from "@/repository/dataUpdater";

export const Axios = axios;

export const Repo = {getSingle, justGetData, justGetList, getList, save, patch, drop, downloadFile,
  uploadFile, post, getSelectPoll, getCollection, get, getSingleSilence, watchFlag, watchState,
  uniSave, showWarning, postWithData, getPreview, watchFlagByTable, getHistory, addMsg, readMsg, checkMsg, msgCounter, getMsgStore}

Axios.defaults.headers = authHeader();

const keys = [{name: 'Понятно', action: 'close'}];

export const STORE = {};
export const SERVICE_STORE = {
  store: new Set(),
  mapping: {counter: 0,
    up: function() {this.counter++},
    down: function() {this.counter--}
  },
  addMsg: async function (msg) {
    if (!this.mapping[msg.what]) {
      this.mapping[msg.what] = {
        counter: 0,
        up: function() {this.counter++},
        down: function() {this.counter--}};
    }
    if (!this.store.has(msg.id)) {
      let entity = await vue.$repo.get(msg.what, msg.whatId);
      entity.service = {message: true};
      vue.$flag.value = {type: msg.what, id: msg.whatId};
      this.store.add(msg.id);
      this.mapping[msg.what][msg.whatId] = msg;
      this.mapping[msg.what].up();
      this.mapping.up();
    }
  },
  dropMsg: function (what, whatId) {
    if (this.mapping[what] && this.mapping[what][whatId]) {
      let msg = this.mapping[what][whatId];
      if (this.store.has(msg.id)) {
        this.store.delete(msg.id);
        this.mapping[what][whatId] = null;
        this.mapping[what].down();
        this.mapping.down();
      }
    }
  },
  check: function (what, whatId) {return this.mapping[what] && this.mapping[what][whatId] && this.mapping[what][whatId].id > 0}
};

export const BACKEND_URL = "/"
export const WS_URL = "/"
//export const WS_URL = "ws://127.0.0.1:8084/"
//export const BACKEND_URL = "/"

export const API_URL = BACKEND_URL + "api/"

export const ASSEMBLY = 'assembly';
export const CATEGORY = 'category';
export const CELL = 'stockcell';
export const COMPANY = 'contact'
export const CONTENT = 'content';
export const DEPARTMENT = 'department';
export const DOCUMENT = 'document'
export const EMPLOYEE = 'employee';
export const ENQUIRE = 'enquire';
export const EQUIPMENT = 'equipment';
export const INVOICE = 'invoice';
export const ITEM = 'item';
export const INCOME = 'income';
export const LNK = 'lnk';
export const NOTE = 'note';
export const ORDER = 'order';
export const ORDER_STATUS = 'orderstatus';
export const PLAN = 'plan';
export const PORTION = 'portion';
export const PRODUCTION = 'production';
export const PURCHASE = 'purchase';
export const RECEIPT = 'receipt';
export const RES = 'res';
export const RESERVE = 'reserve';
export const SIGN = 'sign';
export const STOCK = 'stock';
export const TASK = 'task';
export const WORK_TIME = 'worktime';
export const CERTIFICATE_PATTERN = 'cert_ptt';
export const CERTIFICATE_COUNTER = 'cert_cnt';
export const CERTIFICATE = 'certificate';
export const QUOTATION = 'quotation';
export const QUOTATION_ELEMENT = 'quotation_element';
export const ACCOUNT = 'account';
export const TRANSACTION = 'transaction';
export const SPECIFICATION = 'specification';
export const MESSAGE = 'msg';

export const warning = {header: [], show: false, id: 'WARNING_ALERT', keys: keys};

const downloadingModal = 'DOWNLOADING_MODAL';

function addMsg(msg) {
  SERVICE_STORE.addMsg(msg);
}

function readMsg(what, whatId) {
  SERVICE_STORE.dropMsg(what, whatId);
}

function msgCounter(what) {
  if (what) {
    return SERVICE_STORE.mapping[what].counter;
  }
  return SERVICE_STORE.mapping.counter;
}

function checkMsg(what, whatId) {
  return SERVICE_STORE.check(what, whatId);
}

function getMsgStore() {
  return SERVICE_STORE;
}


function get(type, id, downloading) {
  if (STORE[type] && STORE[type][id]) {
    if (!STORE[type][id].service) {
      STORE[type][id].service = {}
    }
    if (checkMsg(type, id)) {
      STORE[type][id].service.message = true;
    }
    return STORE[type][id];
  }
  return type && id ? getSingle(type + "/" + id, type, downloading) : null
}

function updateWarning(msg, downloading) {
  if (localStorage.getItem('expired') < new Date().getTime()) {
    userService.logout();
    location.reload();
  } else if (msg && (typeof msg === 'string' || msg instanceof String) && !msg.startsWith('<')) {
    if (downloading) {
      downloading.state = false;
      downloading.data = {
        header: msg.includes('$') ? msg.split('$') : [msg],
        keys: keys
      };
      downloading.state = true;
    }
  }
}

function showWarning() {
  this.objects.unshift(object);
}

function watchState(that, type, state) {
  if (state === 'edit') {
    that.object = JSON.parse(JSON.stringify(that.object));
  } else {
    if (that.start_object && that.start_object.id > 0) {
      that.object = that.start_object ? get(type, [that.start_object.id]) : null;
    }
  }
}

function watchFlag(that, type, val, field) {
  if (!field && that.object && that.object.id && val.type === type && val.id === that.object.id) {
    that.object = that.start_object ? get(type, that.start_object.id) : null;
    that.viewKey++;
  } else if (field && that[field] && that[field].id && val.type === type && val.id === that[field].id) {
    that[field] = get(type, val.id);
    that.viewKey++;
  }
}

async function watchFlagByTable(that, val) {
  if (val.type === that.type) {
    let id = val.id;
    let notExist = that.objects && that.objects.length > 0;
    that.objects.forEach(obj => {if (obj.id === id) notExist = false})
    if (notExist) {
      let parentType = that.parent.type;
      let obj = await get(val.type, val.id);
      let objParent = await obj[parentType];
      if (obj && objParent && objParent.id === that.parent.id) {
        that.objects.push(obj);
        that.tableKey++;
      }
    }
  }
}

async function uniSave(that, type, url, downloading) {
  let object = await save(url, type, that.eObject ? that.eObject : that.object, downloading);
  if (object) {
    if (!that.start_object || that.start_object.id < 1) {
      if (that.start_object) {
        that.start_object.id = object.id;
      }
    }
    if (that.start_object.id > 0) {
      that.state = 'full';
    } else if (object.id === -100){
      that.object = null;
      if (that.start_object) {
        that.start_object.id = -100;
      }
      that.state ='';
      that.$emit('drop');
    }
    that.$emit("updated", object);
    return object;
  }
  return null;
}

function callModal() {
  let modal = document.getElementById(downloadingModal);
  if (modal) {
    modal.style.display = "block";
  }
}

function closeModal() {
  let modal = document.getElementById(downloadingModal);
  if (modal) {
    modal.style.display = "none";
  }
}

async function getSingle(url, type, downloading) {
  await checkToken(downloading);
  openState(downloading);
  return await Axios
    .get(API_URL + url)
    .then(response => {
      closeState(downloading);
      if (type) {
        return updateData(response.data, type);
      } else {
        return response.data
      }
    })
    .catch(async function(error) {
      let msg = error.response.data;
      updateWarning(msg, downloading);
      return null;
    });
}

async function getSingleSilence(type, id, version, downloading) {
  await checkToken(downloading);
  if (version && STORE[type] && STORE[type][id] && STORE[type][id][version] && STORE[type][id][version]>= version) {
    return null;
  }
  let url = type + "/" + id
  return await Axios
    .get(API_URL + url)
    .then(response => {
      closeState(downloading);
      return updateData(response.data, type);
    })
    .catch(async function(error) {
      //getSingleSilence(type, id, version).then();
    });
}

async function justGetData(url, downloading) {
  await checkToken(downloading);
  openState(downloading);
  return await Axios
    .get(API_URL + url)
    .then(response => {
      closeState(downloading);
      return response.data;
    })
    .catch(async function(error) {
      let msg = error.response.data;
      updateWarning(msg, downloading);
      return null;
    });
}

async function getHistory(type, id, history, downloading) {
  if (history.show) {
    history.data = await justGetData(`${type}/logg/${id}`, downloading);
  } else {
    history.data = null;
  }
}

async function justGetList(url, targetArray, sortBy, downloading) {
  await checkToken(downloading);
  openState(downloading);
  await Axios
    .get(API_URL + url)
    .then(response => {
      let data = response.data;
      if (sortBy) {
        sorter(data, sortBy);
      }
      data.forEach(single => targetArray.push(single));
      closeState(downloading);
    })
    .catch(async function(error) {
      let msg = error.response.data;
      updateWarning(msg, downloading);
    });
}

async function getCollection(url, type, downloading) {
  let collection = [];
  openState(downloading);
  await getList(url, type, collection);
  closeState(downloading);
  return collection;
}


async function getList(url, type, targetArray, sortBy, downloading) {
  await checkToken(downloading);
  openState(downloading);
  await Axios
    .get(API_URL + url)
    .then(response => {
      closeState(downloading);
      let data = response.data ? response.data : [];
      if (sortBy) {
        sorter(data, sortBy);
      }
      while(targetArray.length > 0) {
        targetArray.pop();
      }
      data.forEach(single => targetArray.push(updateData(single, type)));
    })
    .catch(async function(error) {
      if (error && error.response) {
        closeState(downloading);
        let msg = error.response.data;
        updateWarning(msg, downloading)
      }
      return null;
    });
}

/*
async function getListWithBounds(url, targetArray, bounds) {
  targetArray = [];
  if (!bounds) {
    await Axios
      .get('/api/' + url)
      .then(response => {
        closeModal();
        targetArray = response.data;
      })
  } else {
    url += 'bounds/';
    await Axios
      .post('/api/'+ url, this.selectBounds)
      .then(response => {
        closeModal();
        targetArray = response.data;
      })
  }
}*/

async function save(url, type, object, downloading) {
  await checkToken(downloading);
  openState(downloading);
  return await Axios
    .post(API_URL + url, object)
    .then(response => {
      closeState(downloading);
      return updateData(response.data, type);
    })
    .catch(async function(error) {
      closeState(downloading);
      let msg = error.response.data;
      updateWarning(msg, downloading);
      return null;
    });
}

async function post(url, data, downloading) {
  await checkToken(downloading);
  openState(downloading);
  return await Axios
    .post(data ? API_URL + url : API_URL + url, data)
    .then(response => {
      closeState(downloading);
      return response.data;
    })
    .catch(async function(error) {
      let msg = error.response.data;
      updateWarning(msg, downloading);
      return null;
    });
}

async function drop(url, downloading) {
  await checkToken(downloading);
  openState(downloading);
  return await Axios
    .delete(API_URL + url)
    .then(response => {
      closeState(downloading);
      return true;
    })
    .catch(async function(error) {
      let msg = error.response.data;
      updateWarning(msg, downloading);
      return false;
    });
}

async function patch(url, downloading) {
  await checkToken(downloading);
  openState(downloading);
  return await Axios
    .post(url)
    .then(response => {
      closeState(downloading)
      return updateData(response.data);
    })
    .catch(async function(error) {
      let msg = await error.response.data.text();
      updateWarning(msg, downloading);
      return null;
    });
}

function updateData(data, type) {
  if (!socketOn) {
    openSocket(authHeader().Authorization.substring(7)).then();
  }
  let id = data.id;
  if (type === MESSAGE) {
    addMsg(data);
    return null;
  }
  if (type && id > 0) {
    if (!STORE[type]) {
      STORE[type] = {};
    }
    STORE[type][id] = data;
    if (!STORE[type][id].service) {
      STORE[type][id].service = {}
    }
    if (checkMsg(type, id)) {
      STORE[type][id].service.message = true;
    }
    vue.$flag.value = {type: type, id: id};
    return STORE[type][id];
  } else {
    return data;
  }
}

async function checkToken(downloading) {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken && user.expired < new Date().getTime() - 7200000) {
    //console.log(new Date().getTime() - 7200000 + " - " + user.expired)
    await Axios
      .get(API_URL + 'auth/refresh')
      .then(response => {
        user.accessToken = response.data.token;
        localStorage.setItem('user', user);
        Axios.defaults.headers = authHeader();
      })
      .catch(async function(error) {
        let msg = error.response.data;
        openState(downloading);
        updateWarning(msg, downloading);
      });
  }
}

async function downloadFile(url, fileName, downloading) {
  openState(downloading);
  return await Axios({
    url: API_URL + url,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      console.log(fileName);
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      if (fileName.toLowerCase().endsWith('pdf')) {
        window.open().document.body.appendChild(link);
      } else {
        document.body.appendChild(link);
      }
      link.click();
      closeState(downloading);
      return "ok";
    })
    .catch(async function(error) {
      closeState(downloading);
      let msg = await error.response.data.text();
      updateWarning(msg, downloading);
  });
}

async function getPreview(id) {
  return await Axios({
    url: API_URL + 'lnk/preview/' + id,
    method: 'GET',
    responseType: 'arraybuffer',
  })
    .then((response) => {
      return 'data:image/png;base64,'+ base64ArrayBuffer(response.data);
    })
    .catch(async function(error) {
      //closeModal();
    });
}


async function postWithData(url, data, fileName, downloading) {
  await checkToken(downloading);
  openState(downloading);
  return await Axios({
    url: API_URL + url,
    method: 'POST',
    responseType: 'blob',
    data: data
  })
    .then(response => {
      closeState(downloading);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      if (fileName.toLowerCase().endsWith('pdf')) {
        window.open().document.body.appendChild(link);
      } else {
        document.body.appendChild(link);
      }
      link.click();
      return "ok";
    })
    .catch(async function(error) {
      let msg = error.response.data;
      updateWarning(msg, downloading);
      return null;
    });
}

async function uploadFile(url, formData, downloading) {
  openState(downloading);
  return await Axios.post(API_URL + url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(response => {
    closeState(downloading);
    return response.data;
  }).catch(async function(error) {
    let msg = error.response.data;
    updateWarning(msg, downloading);
  });
}

async function getSelectPoll(type, bounds, selectPool, downloading) {
  while(selectPool.length > 0) {
    selectPool.pop();
  }
  if (!this.selectBounds) {
    await justGetList(type + '/', selectPool, null, downloading);
  } else {
    (await save(type + '/bounds/', '', bounds)).forEach(data => selectPool.unshift(data));
  }
}

function base64ArrayBuffer(arrayBuffer) {
  let base64    = ''
  let encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  let bytes         = new Uint8Array(arrayBuffer)
  let byteLength    = bytes.byteLength
  let byteRemainder = byteLength % 3
  let mainLength    = byteLength - byteRemainder

  let a, b, c, d
  let chunk

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
    d = chunk & 63               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength]

    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3)   << 4 // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '=='
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15)    <<  2 // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '='
  }

  return base64
}

function closeState(obj) {
  if (obj) {
    obj.state = false;
  }
}

function openState(obj) {
  if (obj) {
    obj.state = true;
  }
}
