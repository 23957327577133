<template>

  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object" class="row row-title" v-on:click="fullView">
      <div class="col-7">
        {{ object.name }}
        <span v-if="object.service && object.service.message" style="color: #ef7676; padding-left: 5px">
          {{ 'new' }}
        </span>
      </div>
      <div class="col-5">
        {{ getName(object.category) }}
        <span v-if="select">
          <i class="nc-icon nc-check-2 float-right" v-on:click="selectObject"></i>
        </span>
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header ? header : 'Номенклатура:' }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.name + ', ' + object.measure }}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Номенклатура'" :select="select"
                                :extra-keys="[{action: 'clone', name: 'Копировать'}]"
                                :historical="true"
                                @regularState="state = default_state ? default_state : start_state"
                                @history="irc.controlHistory()"
                                @editState="irc.startEdit()" @select="selectObject" @clone="makeClone">
              </full-view-header>

              <row :header="'Наименование:'" :value="object.name" :copyable="true"></row>

              <row :header="'Единица измерения:'" :value="object.measure"></row>

              <row :header="'Категория:'" :value="getName(object.category)"></row>

              <row :header="'Неснижаемый остаток:'" :value="object.minimum ? object.minimum : '-'"></row>

              <row :header="'Автосборка:'" :value="object.built ? 'Да' : 'Нет'"></row>

              <lnk v-if="object.image && object.image.id" :lnk_start="object.image" :state_start="'asImage'"></lnk>
                <div v-if="!select">
                <div class="row lnk-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showLnk" v-on:click="getLnk(true)">Файлы</span>
                    <lnk-table v-if="showLnk" @element="hideElement" :header="'Файлы'"
                               :downloading="downloading.lnk"
                               :header-title="'Связные файлы и изображения'"
                               :objects="lnks" :parent="asParent">Lnk</lnk-table>
                  </div>
                </div>
                <div class="row note-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showNote" v-on:click="getNote(true)">Заметки</span>
                    <note-table v-if="showNote" @element="hideElement" :header="'Заметки'"
                                :downloading="downloading.note"
                                :header-title="'Заметки и прочее'" :showAll="true"
                                :notes="notes" :parent="asParent">Content</note-table>
                  </div>
                </div>
                <div class="row content-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showContent" v-on:click="getContent">Комплектация</span>
                    <contents-table v-if="showContent" @element="hideElement" :header="'Комплектация'"
                                    :downloading="downloading.content"
                                    :header-title="'Составные материалы и комплектующие'"
                                    :objects="contents" :parent="asParent">Content</contents-table>
                  </div>
                </div>

                  <div v-if="access" class="row stock-bcg margin-5">
                    <div class="col-12 unselectable">
                      <span v-if="!showPortion" v-on:click="getPortion">История отгрузок</span>
                      <portion-table v-if="showPortion" @element="hideElement('portion')" :header="'История отгрузок'"
                                     :downloading="downloading.portion"
                                     :header-title="'Отгрузки и перемещения номенклатурной единицы'"
                                     :objects="portions" :parent="asParent">Content</portion-table>
                    </div>
                  </div>

                  <certificate v-if="showCertificate" :start="certificate" :start_state="'text'" :parent="asParent"
                               @updated="setCertificate"></certificate>
                  <br/>
              </div>
              <row :header="'Аналоги:'" :value="object.analogues ? object.analogues : 'Нет данных'"></row>

              <event-table v-if="irc.history.show && irc.history.data && !select"
                           :objects="irc.history.data" :downloading="downloading.history"></event-table>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Наименование:'" class="full-width"></input-row>

            <input-row v-model="object.measure" :header="'Единица измерения:'" class="full-width"></input-row>

            <div class="row">
              <div class="col-3">
                <a class="input-title">
                  Категория:
                </a>
              </div>
              <div class="col-9">
                <item-category-select v-bind:category="object.category" @category="setCategory"> categories </item-category-select>
              </div>
            </div>
            <br/>

            <input-row v-model="object.minimum" :header="'Неснижаемый остаток:'" :number="true" class="full-width"></input-row>

            <div class="row">
              <div class="col-3">
                <a class="input-title">
                  Автосборка:
                </a>
              </div>
              <div class="col-9">
                <input type="checkbox" id="scales" name="scales" v-model="object.built" checked>
                <span style="margin-left: 10px">
                  {{object.built ? 'Да' : 'Нет'}}
                </span>
              </div>
            </div>
            <br/>


            <div>
              <div v-if="object.image && object.image.id">
                <lnk :lnk_start="object.image" :select-pool="lnks" :select="true" :state_start="'asImage'"
                     @select="setImage" @callData="giveData"> </lnk>
              </div>
              <div v-else>
                <lnk :select-pool="lnks" :select="true" :state_start="'asImage'" @select="setImage" @callData="giveData"> </lnk>
              </div>
            </div>

            <br/>
            <input-row v-model="object.analogues" :header="'Аналоги:'" class="full-width"></input-row>

            <div class="row" v-if="object && object.id === 0 && object.service">
              <div class="col-3">
                <a class="input-title">
                  Клонировать комплектацию:
                </a>
              </div>
              <div class="col-9">
                <span class="input-title">
                  <input type="checkbox" v-model="object.service.cloneContent" checked>
                  <span style="margin-left: 10px">
                    {{object.service.cloneContent ? "Да" : "Нет"}}
                  </span>
                </span>
              </div>
            </div>
            <div v-else class="row item-bcg margin-for-inner-obj">
              <item :start_object="cloneItem" :start_state="'text'" :default_state="'text'"
                          :select="true" @select="setContentFromClone" :header="'Клонировать комплектацию от:'"> Item </item>
            </div>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <items-table v-bind:objects="selectPool" v-bind:header="'Номенклатура'"
                   :downloading="downloading.select"
                   @select="selectObjectFromTable" @closeModal="closeModal"
                   v-bind:header-title="'Перечень продукции и материалов'" :select="select"></items-table>
    </div>

  </div>
</template>
<script>

import {authHeader, vue} from "@/auth";
import ItemCategorySelect from "../selectable/ItemCategorySelect";
import ContentsTable from "@/tables/ContentsTable";
import Table from "@/components";
import {ElementInterface} from "@/models/elementInteface";
import {CERTIFICATE, ENQUIRE, ITEM, LNK, NOTE, PLAN, Repo, STORE} from "@/repository/repository";
import {userService} from "@/services";

export default {
  components: {
    Table,
    ContentsTable,
    ItemCategorySelect
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      contents: [],
      showContent: false,
      lnks: [],
      showLnk: false,
      notes: [],
      showNote: false,
      portions: [],
      showPortion: false,
      id: null,
      selectPool: [],
      asParent: {},
      access: false,
      viewKey: 0,
      flag: this.$flag,
      certificate: null,
      showCertificate: false,
      irc: null,
      showSelect: false,
      downloading: {state: false, select: {state: false}, content: {state: false}, lnk: {state: false}, note: {state: false}, history: {state: false}, portion: {state: false}},
      cloneItem: {},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    startEdit() {
      this.state = 'edit';
      this.showHistory = false;
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
        this.object.service = null;
      } else {
        this.$emit('close', true);
      }
    },
    async getContent() {
      this.contents = [];
      this.showContent = true;
      await this.$repo.justGetList('item/content/' + this.object.id, this.contents, null, this.downloading.content);
    },
    async getLnk() {
      this.lnks = [];
      this.showLnk = true;
      await this.$repo.getList('item/lnk/' + this.object.id, LNK, this.lnks, null, this.downloading.lnk);
    },
    async getNote() {
      this.notes = [];
      this.showNote = true;
      await this.$repo.getList('item/note/' + this.object.id, NOTE, this.notes, null, this.downloading.note);
    },
    async getPortion() {
      this.portions = [];
      let url = 'portion/item?id=' + this.object.id;
      this.showPortion = true;
      await Repo.justGetList(url, this.portions, {key: 'document.date', asc: false}, this.downloading.portion);
    },
    hideElement(element) {
      if (element === 'content') {
        this.showContent = false;
        this.contents = [];
      } else if (element === 'lnk') {
        this.showLnk = false;
        this.lnks = [];
      } else if (element === 'note') {
        this.showNote = false;
        this.notes = [];
      } else if (element === 'portion') {
        this.showPortion = false;
        this.portions = [];
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setCategory(category) {
      this.object.category = category;
    },
    setCertificate(certificate) {
      this.certificate = certificate;
    },
    setContentFromClone(item) {
      this.object.service = {cloneContent: true, id: item.id}
    },
    save() {
      this.$repo.uniSave(this, ITEM, 'item/', this.downloading);
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    makeClone() {
      this.$emit('clone', this.object.id)
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.callModal();
      await this.$repo.getSelectPoll(ITEM, this.selectBounds, this.selectPool, this.downloading.select);
    },
    giveData(type) {
      if (type === 'lnk') {
        this.getLnk(false);
      }
    },
    async getCertificate() {
      let certificate = await this.$repo.getSingle(CERTIFICATE + '/i' + this.object.id, CERTIFICATE, this.downloading);
      this.certificate = certificate.id < 1 ? null : certificate;
      this.showCertificate = true;
    },
    async controlHistory() {
      await this.$repo.getHistory(this, ITEM, this.object.id, this.downloading.history);
    }
  },
  mounted: async function () {
    this.irc = new ElementInterface(this, ITEM, this.downloading.history);
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(ITEM, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    this.access = userService.userHasAccess('ADMIN');
    if (!this.object) {
      this.object = await this.$repo.justGetData('item/model', this.downloading);
    }
  },
  watch: {
    object: function () {
      if (this.object) {
        this.asParent = {type: ITEM, id: this.object.id}
      }
    },
    state: async function (value) {
      this.$repo.watchState(this, ITEM, value);
      if (this.state === 'full') {
        await this.getCertificate();
      }
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, ITEM, val);
    },
  }
}
</script>
<style>
</style>
