//Sorter by key
export function sorter(object, sortBy) {
  if (object && sortBy && (sortBy.key || sortBy.fnc)) {
    return object.sort(function (a, b){
      if (object.length > 1) {
        let varA = 0;
        let varB = 0;
        if (sortBy.key) {
          varA = textForFieldWithPoints(a, sortBy.key);
          varB = textForFieldWithPoints(b, sortBy.key);
        } else if (sortBy.fnc) {
          varA = sortBy.fnc(a);
          varB = sortBy.fnc(b);
        }
        if (sortBy.asc) {
          return varA > varB || !varB ? 1 : -1;
        } else {
          return varB > varA || !varA ? 1 : -1;
        }
      }
    });
  } else {
    return object;
  }
}

//Check object fits filters
export function fitFilter(object, filter) {
  for (let [key, value] of filter.entries()) {
    if (value.id !== undefined ) {
      let filterField = textForFieldWithPoints(object, key);
      if(filterField === undefined || textForFieldWithPoints(object, key).id !== value.id) {
        return false;
      }
    }
  }
  return true;
}

//Check object fits filters
export function updateFilter(filter, entity) {
  if (entity.value === undefined && filter.has(entity.key)) {
    filter.delete(entity.key);
  } else {
    filter.set(entity.key, entity.value);
  }
  return filter;
}


//Returns string for complicated objects
export function textForFieldWithPoints(obj, longWay) {
  let rsl;
  longWay = longWay.split("&&");
  for (let i = 0; i < longWay.length; i++) {
    if (i > 0) {
      rsl += " / ";
    }
    if (longWay[i].includes('.')){
      let way = longWay[i].split('.');
      let tmp = obj;
      for (let n = 0; n < way.length; n++){
        if (tmp === undefined || tmp === null || tmp[way[n]] === undefined) {
        }
        if (tmp !== undefined && tmp !== null) {
          tmp = tmp[way[n]];
        } else {
          tmp = "";
          break;
        }
      }
      if (i > 0) {
        rsl += tmp;
      } else {
        rsl = tmp;
      }
    } else {
      if (i > 0) {
        rsl += obj[longWay[i]];
      } else {
        rsl = obj[longWay[i]];
      }
    }
  }
  return rsl;
}

//Format text for search
export function formatSearchText(text) {
  return text ? text.replace(/[\\\s,./:;"'-]/g, '').toLowerCase() : "";
}

//Text contains text
export function textContainsText(text, cont) {
  if (cont.length === 0) {
    return true;
  }
  text = formatSearchText(Array.isArray(text) ? text.join(' ') : text);

  let rsl = [];
  cont.split(' ').forEach(word => {
    if (word.length > 0) {
      rsl.push({word: formatSearchText(word), times: 0});
    }
  });
  rsl.forEach(test => {
    if (text.includes(test.word)) {
      test.times++;
    }
  });
  let final = true;
  rsl.forEach(test => {
    if (test.times === 0) {
      final = false;
    }
  });
  return final;
}

export function asDate(value) {
  if (value !== undefined) {
    let date = new Date(value);
    return date.customFormat('#DD#/#MM#/#YYYY#');
  } else {
    return '-'
  }
}

export function parentToParams(parent) {
  return this.parent ? ('?type=' + parent.type() + '&id=' + parent.id) : '';
}

export function addToList(object, that) {
  if (object.id > 0) {
    let notExist = true;
    for (let i = 0; i < that.objects.length; i++) {
      if (that.objects[i].id === object.id) {
        that.objects[i] = object;
        notExist = false;
        break;
      }
    }
    if (notExist) {
      if (that.newObj) {
        that.newObj = null;
      }
      that.objects.unshift(object);
    }
  }
}
