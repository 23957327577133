<template>
  <div class="content">
    <div class="container-fluid">
      <moves-table :objects="objects" :header="'Перемещение продукции'"
                       :downloading="downloading"
                       :header-title="'Перемещение, поступление, списание продукции и компелектующих'" :hide-price="true"
                       @update="getMoves"></moves-table>
    </div>
  </div>
</template>
<script>
import {authHeader} from "@/auth";
import MovesTable from "@/tables/MovesTable";
import {sorter} from "@/services";

export default {
  name: 'moves',
  components: {MovesTable},
  data () {
    return {
      objects: [],
      key: authHeader(),
      filter: new Map,
      tableKey: 0,
      downloading: {state: false, history: {state: false}},
    }
  },
  methods: {
    async getMoves() {
      this.objects = [];
      let tmp = [];
      let tmpMap = await this.$repo.justGetData('moves/', this.downloading);
      if (tmpMap && Object.keys(tmpMap)) {
        Object.keys(tmpMap).forEach(key => {
          if (tmpMap[key]) {
            tmpMap[key].forEach(val => {
              tmp.push({type: key, obj: val})
            })
          }
        })
      }
      sorter(tmp, {key: 'obj.date', asc: false});
      this.objects = tmp;
    }
  },
  created() {
    this.getMoves();
  }
}
</script>
<style>
</style>
