<template>
  <div class="full-width">
    <div v-if="object && object.item" class="row row-title">
      <div class="col-4">
        {{ object.item.name }}
      </div>
      <div class="col-5">
        {{ object.stock.contact.name + " - " + object.stock.name }}
      </div>
      <div class="col-3" :style="(object.stockData ? object.stockData : 0) - (object.item.minimum ? object.item.minimum : 0) > 0 ? 'color: black' : 'color: red'">
        {{ (object.stockData ? object.stockData + (object.item.measure ? (' ' + object.item.measure) : ''): '-')}}
        {{ object.item.minimum ? (' / ' + object.item.minimum + (object.item.measure ? (' ' + object.item.measure) : '')) : ''}}
      </div>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";

export default {
  components: {
    Table
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      reserves: [],
      showReserve: false,
      id: null,
      selectPool: [],
      asParent: {}
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    }
  },
  mounted: function () {
    this.object = this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
    object: function () {
      this.asParent = {type:'stockcell', id: this.object.id}
    }
  }
}
</script>
<style>
</style>
