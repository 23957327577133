<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.out" class="row row-title" v-on:click="fullView()">
      <div class="col-5">
        {{ object.out.item.name }}
      </div>
      <div class="col-3">
        {{ (object.value % 1 === 0 ? object.value : (Math.round(object.value * 1000) / 1000).toFixed(3)) + ' ' + object.out.item.measure}}

        <span v-if="object.distributedValue > 0">
          /
          <span style="color: #0a3167">
            {{object.distributedValue.toFixed(2)}}
          </span>
        </span>

        <span v-if="object.distributedValue> 0 && object.distributedValue < object.value">
          /
          <span style="color: #0a6715">
            {{(object.value - object.distributedValue).toFixed(2)}}
          </span>
        </span>
      </div>
      <div class="col-2">
        {{ (Math.round(object.price * 100) / 100).toFixed(2) }}
      </div>
      <div class="col-2">
        {{ (Math.round(object.price * object.value * 100) / 100).toFixed(2) }}
      </div>
    </div>

    <div v-if="state === 'move' && object && object.out" class="row row-title" v-on:click="fullView()">
      <div class="col-2">
        Перемещение
      </div>
      <div class="col-2">
        {{ asDate }}
      </div>
      <div class="col-6">
        {{ object.out.item.name }}
      </div>
      <div class="col-2">
        {{ asValue(object.value) + ' ' + object.out.item.measure}}
      </div>
    </div>

    <div v-if="state === 'text'" class="row unselectable" v-on:click="dispatcher()">
      <div class="col-3">
        <a class="input-title">
          {{ header }}
        </a>
      </div>
      <div class="col-9" v-if="object && object.id > 0">
        {{ object.out.item.name + ' - ' + object.value + ', ' + object.out.item.measure}}
      </div>
      <div class="col-9" v-else>
        Выбрать
      </div>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Перемещаемая позиция'" :select="select"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="state = 'edit'" @select="selectObject">
                Header
              </full-view-header>

              <div v-if="(parent && parent.type !== 'item') || !parent" class="row item-bcg margin-for-inner-obj">
                <stock-cell v-if="object.out.stock.contact" :start_object="object.out" :header="'Номенклатура'"
                         :start_state="'text'" :default_state="'text'"></stock-cell>
                <item v-else :start_object="object.out.item" :header="'Номенклатура'"
                            :start_state="'text'" :default_state="'text'"></item>
              </div>

              <div v-if="(parent && (parent.type === 'item' || parent.type === 'receipt')) || !parent" class="row document-bcg margin-for-inner-obj">
                <document :start_object="object.document" :header="'Отгрузка'"
                          :start_state="'text'" :default_state="'text'"></document>
              </div>

              <row :header="'Количество, ' + object.out.item.measure + ':'" :value="object.value" :as-value="true"> Value </row>

              <row v-if="object.distributedValue" :header="'Распределено / свободно, ' + object.out.item.measure + ':'"
                   :value="object.distributedValue.toFixed(2) + ' / ' + (object.value - object.distributedValue).toFixed(2)">
                DistributedValue
              </row>

              <row v-if="object.tax > 0" :header="'Цена без налога:'" :value="object.price / (1 + object.tax / 100)" :as-money="true"> Price without tax</row>

              <row :header="'Цена:'" :value="object.price" :as-money="true"> Price </row>

              <row :header="'Сумма:'" :value="object.price * object.value" :as-money="true"> Sum </row>

              <row :header="'Налог:'" :value="object.tax" :as-money="true"> Tax </row>

              <certificate v-if="showCertificate" :start="certificate" :start_state="'text'" :parent="asParent"
                           @updated="setCertificate"></certificate>

              <div v-if="!select">
                <div v-if="object.document.purchase" class="row receipt-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showReceipt" v-on:click="getReceipt">Распределение по закупке</span>
                    <receipts-table v-if="showReceipt" @element="hideElement('receipt')" :header="'Распределение'"
                                    :downloading="downloading.receipt"
                                    :header-title="'Распределение в рамках закупки'"
                                    :objects="receipts" :parent="asParent">Receipts</receipts-table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>
            <div v-if="parent" class="row margin-for-inner-obj">
              <stock-cell :start_object="object.out" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setCellOut" :header="'Номенклатура:'" :parent="parent"> Stock cell </stock-cell>
            </div>

            <input-row v-model="object.value" :header="'Количество:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="priceTaxFree" :header="'Цена без налога:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="object.price" :header="'Цена:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="moneyValue" :header="'Сумма:'" :number="true" class="full-width" ></input-row>

            <input-row v-model="object.tax" :header="'Налог:'" :number="true" class="full-width" ></input-row>

            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <portion-table v-bind:objects="selectPool" v-bind:header="'Позиции по документам'" @select="selectObjectFromTable"
                     :downloading="downloading.select"
                     @closeModal="closeModal" :parent="parent"
                     v-bind:header-title="'Позициий в документе с количеством и ценой'" :select="select"></portion-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {CERTIFICATE, ITEM, PORTION, RECEIPT} from "@/repository/repository";

export default {
  components: {
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      receipts: [],
      showReceipt: false,
      selectPool: [],
      asParent: {},
      moneyValue: 0,
      priceTaxFree: 0,
      viewKey: 0,
      flag: this.$flag,
      certificate: null,
      showCertificate: false,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}, receipt: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    asValue(data) {
      return data % 1 === 0 ? data : (Math.round(data * 1000) / 1000).toFixed(3);
    },
    fullView() {
      if (this.object.id > 0) {
        this.state = 'full';
      } else {
        this.$emit('close', true);
      }
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setCellOut(cell) {
      if (this.object) {
        this.object.out = cell;
      }
    },
    setCertificate(certificate) {
      this.certificate = certificate;
    },
    save() {
      let url = 'portion/';
      if (this.parent && this.object.id === 0) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.$repo.uniSave(this, PORTION, url, this.downloading);
    },
    async getReceipt() {
      this.receipts = [];
      this.showReceipt = true;
      await this.$repo.getList('portion/receipt/' + this.object.id, RECEIPT, this.receipts, null, this.downloading.receipt);
    },
    hideElement(element) {
      if (element === RECEIPT) {
        this.showReceipt = false;
        this.receipts = [];
      }
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      if (this.parent) {
        await this.$repo.justGetList('portion/?type=' + this.parent.type + '&id=' + this.parent.id, this.selectPool, null, this.downloading.select);
      } else {
        await this.$repo.justGetList('portion/', this.selectPool, null, this.downloading.select);
      }
      this.callModal();
    },
    async getCertificate() {
      let certificate = await this.$repo.getSingle(CERTIFICATE + '/p' + this.object.id, CERTIFICATE, this.downloading);
      this.certificate = certificate.id < 1 ? null : certificate;
      this.showCertificate = true;
    }
  },
  mounted: async function () {
    this.state = this.start_state;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(PORTION, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
  },
  watch: {
    object: function () {
      if (this.object && this.object.id) {
        this.asParent = {type: PORTION, id: this.object.id};
      }
      if (this.state === 'edit') {
        this.moneyValue = this.object.price * this.object.value;
        this.priceTaxFree = this.object.price * (this.object.tax / 100 + 1);
      }
    },
    moneyValue: function () {
      this.object.price = this.moneyValue / this.object.value;
      if (this.object.tax > 0) {
        this.priceTaxFree = this.object.price / (1 + this.object.tax / 100);
      }
    },
    priceTaxFree: function () {
      if (!this.object.price > 0) {
        this.object.tax = 20;
      }
      this.object.price = this.priceTaxFree * (1 + this.object.tax / 100);
    },
    state: async function (value) {
      this.$repo.watchState(this, PORTION, value);
      if (this.state === 'full') {
        await this.getCertificate();
      }
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, PORTION, val);
    },
  }
}
</script>
<style>
</style>
