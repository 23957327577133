<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card body-classes="table-full-width" style="margin: 5px">
          <template slot="header">
            <span class="float-right unselectable" v-on:click="openSelectModal">
              Добавить
            <i class="nc-icon nc-simple-add"></i>
          </span>
            <h4 class="card-title unselectable" v-on:click="getObjects">{{ header }}</h4>
            <p class="card-category unselectable">{{ headerTitle }}</p>
          </template>
          <table class="table table-hover table-striped">
            <thead>
            <slot name="columns">
              <tr>
                <th class="row-title">
                  <div class="row full-width">
                    <div class="col-12 unselectable">
                        <span v-on:click="sortBy = {key: 'name', asc: !sortBy.asc}">
                          Наименование
                        </span>
                    </div>
                  </div>
                </th>
              </tr>
            </slot>
            </thead>
            <tbody :key="tableKey">
            <tr v-for="(object, index) in objects" :key="index">
              <td>
                <div class="row full-width">
                  <div class="col-md-10">
                    {{ object }}
                  </div>
                  <div class="col-md-2">
                    <span class="unselectable" v-on:click="drop(object, index)">
                      УДАЛИТЬ
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </card>
      </div>

    </div>

    <div class="modal" :id="id">
      <div v-if="selectPool" class="modal-content-50">
        <div class="row">
          <div class="col-12">
            <card body-classes="table-full-width" style="margin: 5px">
              <template slot="header">
                <h4 class="card-title unselectable" v-on:click="closeModal">{{ header }}</h4>
                <p class="card-category unselectable">{{ headerTitle }}</p>
              </template>

              <table class="table table-hover table-striped">
                <thead>
                <slot name="columns">
                  <tr>
                    <th class="row-title">
                      <div class="row" style="width: 100%">
                        <div class="col-12 unselectable">
                        <span v-on:click="sortBy = {key: 'name', asc: !sortBy.asc}">
                          Наименование
                        </span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </slot>
                </thead>
                <tbody :key="tableKey">
                <tr v-for="(object, index) in selectPool" :key="index">
                  <td>
                    <div class="row">
                      <div class="col-md-12">
                  <span v-on:click="selectItemFromTable(object)">
                    {{ object }}
                  </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="downloading && downloading.state">
                  <downloading :downloading="downloading">
                    Downloading
                  </downloading>
                </tr>
                </tbody>
              </table>
            </card>
          </div>
        </div>
      </div>
    </div>

  </div>


</template>
<script>
import {authHeader} from "@/auth";
import {sorter, fitFilter, updateFilter} from '@/services'

export default {
  data () {
    return {
      key: authHeader(),
      sortBy: {key: 'name', asc: true},
      tableKey: 0,
      id: null,
      objects: [],
      selectPool: []
    }
  },
  props: {
    header: null,
    headerTitle: null,
    objectsLink: null,
    selectLink: null,
    dropLink: null,
    addLink: null,
    nameFunction: null,
    downloading: null,
  },
  methods: {
    async getObjects() {
      this.objects =[];
      await this.$repo.justGetList(this.objectsLink, this.objects, this.sortBy, this.downloading);
    },
    sort() {
      sorter(this.objects, this.sortBy);
      this.tableKey += 1;
    },
    async selectItemFromTable(object) {
      this.closeModal();
      if (object) {
        let notExist = true;
        for (let i = 0; i < this.objects.length; i++) {
          if (this.objects[i] === object) {
            this.objects[i] = object;
            notExist = false;
            break;
          }
        }
        if (notExist) {
          await this.$repo.post(this.addLink.replace('#name#', object), this.downloading);
          this.objects.unshift(object);
        }
      }
    },
    headerClick() {
      this.$emit('update');
    },
    async drop(object, index) {
      await this.$repo.post(this.dropLink.replace('#name#', object), this.downloading);
      this.objects.splice(index, 1);
    },
    closeModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "none";
    },
    async openSelectModal() {
      this.selectPool = [];
      await this.$repo.justGetList(this.selectLink, this.selectPool, this.sortBy, this.downloading);
      this.callModal();
    },
    callModal() {
      let modal = document.getElementById(this.id);
      modal.style.display = "block";
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      }
    }
  },
  watch: {
    sortBy: function () {
      this.sort()
    },
    objects: function (){
      this.tableKey += 1;
    }
  },
  mounted() {
    this.id = this._uid;
    this.getObjects();
  }
}
</script>
<style>
</style>
