import SockJS from "sockjs-client";
import {vue} from "@/main";
import {API_URL, BACKEND_URL, MESSAGE, WS_URL} from "@/repository/repository";
import Stomp from "stompjs";

var socket = null;
var stompClient = null;
var tok = '';
var tries = 0;
const StompJs = require('@stomp/stompjs');

export var socketData = {value: null}
export var socketState = {state: socketOn ? 'ON' : 'OFF'}

export async function openSocket(tkn) {
  tok = tkn;
  console.log(`Try ${++tries}`)
  if (!socketOn && localStorage.getItem('expired') > new Date().getTime()) {
    socketOn = true;
    socket = await new SockJS(`${API_URL}ws/?tkn=${tkn}`);
    stompClient = Stomp.over(socket);
    stompClient.connect({}, onConnected, onError);
    socketOn = true;
  }
}


/*export async function openSocket(tkn) {
  tok = tkn;
  socketState.state = socketOn;
  console.log(`Try ${++tries} ${socketState.state}`)
  if (!socketOn && localStorage.getItem('expired') > new Date().getMilliseconds()) {
    socketOn = true;
    let bu = "ws://ap/go?tkn=" + tkn
    socket = new WebSocket(bu);
    stompClient = new StompJs.Client({
      //webSocketFactory: () => {return  socket},
      brokerURL: bu,
      reconnectDelay: 5000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      logRawCommunication: true,
      debug: (x) => console.log(x),
      onConnect: () => {
        stompClient.subscribe("/user/all/data", onMessageReceived);
        socketState.state = 'ON';
        console.log('onConnect called');
      },
      onStompError: (frame) => {console.log('STOMP ERROR')},
      onWebSocketError: evt => {console.log('SOCKET ERROR')},
      onWebSocketClose: evt => {console.log('SOCKET CLOSED')},
      onChangeState: (state) => {console.log(state)},
      onUnhandledReceipt: (frame) => {console.log('UNHANDLED')},
      onUnhandledFrame: (frame) => {console.log('UNHANDLED FRAME')},
      onDisconnect: (frame) => {console.log('DISCONECTED')},
      onUnhandledMessage: message => {console.log(message)},
    });
    console.log("CONFIG DONE")
    stompClient.activate();

    //stompClient.connect({}, onConnected, onError);
    //stompClient.subscribe("/user/all/data", onMessageReceived);

    /*stompClient.connect({}, function (frame) {
      stompClient.subscribe("/user/all/data");
      socketState.state = 'ON FULL';
      console.log(`STOMP connected, try: ${tries}`);
    });*/
    //console.log("DONE");

//  }
//}

const onConnected = () => {
  console.log(`STOMP connected, try: ${tries}`);
  stompClient.subscribe("/user/all/data", onMessageReceived);
  socketState.state = 'ON FULL';
}

const onMessageReceived = (msg) => {
  let data = msg.body.split(":");
  if (data.length > 1) {
    let type = data[0];
    let id = data[1];
    let version = data.length === 3 ? data[2] : null;
    vue.$repo.getSingleSilence(type, id, version).then();
  }
}

const delay = (delayInms) => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
};

const onError = async function(msg) {
  if (msg && msg.startsWith('Whoops!')) {
    console.log(`SOCKET ERROR, try: ${tries}, token=${tok}, msg: ${msg}`)
    socketOn = false;
    socketState.state = "OFF";
    await delay(5000);
    await openSocket(tok);
  } else {

  }

}

export var socketOn = false;
