<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'row' && object && object.id > 0" class="row row-title" v-on:click="fullView()">
      <div class="col-4">
        {{ object.name }}
        <span v-if="object.service && object.service.message" style="color: #ef7676; padding-left: 5px">
          {{ 'new' }}
        </span>
      </div>
      <div class="col-2" v-if="object.ordersStatus">
        {{ object.ordersStatus.name }}
      </div>
      <div class="col-2" v-else>
        -
      </div>
      <div class="col-3">
        {{ object.contact.name }}
      </div>
      <div class="col-3">
        {{ object.consumer.contact.name }}
        <span v-if="select">
          <i class="nc-icon nc-check-2 float-right" v-on:click="selectObject()"></i>
        </span>
      </div>
    </div>

    <div v-if="state === 'text'" v-on:click="dispatcher()">
      <row :header="header" :value="object && object.id > 0 ? object.name : 'Выбрать'">Text</row>
    </div>

    <div v-if="state === 'active' && object && object.id > 0" class="row row-title" v-on:click="">
      <table style="width: 100%; margin-bottom: 10px; margin-right: 20px">
        <colgroup>
          <col v-for="index in 40" style="width: 2.5%" :key="index">
        </colgroup>
        <tr>
          <td colspan="40" class="order-bcg-light left-down-border">
            <div class="row">
              <div class="col-5">
                <i v-if="!showTree" class="nc-icon nc-stre-down margin-right-icon" v-on:click="showTree = true"/>
                <i v-else class="nc-icon nc-stre-up margin-right-icon" v-on:click="showTree = false"/>
                <span class="unselectable" v-if="object && activeState === 'row'" v-on:click="activeState = 'full'">
                  {{ `${object.name}` }}
                  <span v-if="object.service && object.service.message" style="color: #ef7676; padding-left: 5px">
                    {{ 'new' }}
                  </span>
                </span>
              </div>
              <div class="col-5">
                {{ object.consumer.name !== object.consumer.contact.name ? (object.consumer.name + ' (' + object.consumer.contact.name + ')') : object.consumer.name}}
              </div>
              <div class="col-2">
                {{ toDate(object.created )}}
              </div>
            </div>
            <table v-if="object && activeState === 'full'" class="full-width">
              <tr>
                <td class="full-width">
                  <div class="row full-width full-title">
                    <div class="col-12">
                      <br/>
                      <full-view-header :header="'Заказ'" :select="select"
                                        :historical="true"
                                        @regularState="activeState = 'row'"
                                        @history="irc.controlHistory()"
                                        @editState="irc.startEdit()" @select="selectObject">
                        Header
                      </full-view-header>

                      <row :header="'Описание заказа:'" :value="object.name">Description</row>

                      <row :header="'Основание:'" :value="object.basis">Basis</row>

                      <row :header="'Статус заказа:'" :value="object.ordersStatus ? object.ordersStatus.name : '-'">Basis</row>

                      <div class="row company-bcg margin-for-inner-obj">
                        <company :start_object="object.contact" :header="'Поставщик'"
                                 :start_state="'text'" :default_state="'text'"></company>
                      </div>
                      <div class="row stock-bcg margin-for-inner-obj">
                        <stock :start_object="object.consumer" :header="'Заказчик'"
                               :start_state="'text'" :default_state="'text'"></stock>
                      </div>

                      <div v-if="!select">

                        <div class="row plan-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showPlan" v-on:click="showPlan = true">Список по заказу</span>
                            <plan-table v-else @element="showPlan = false" :header="'Состав заказа'"
                                        :downloading="downloading.plan"
                                        :header-title="'Номенклатура к отгрузке по заказу'"
                                        :objects="plans" :parent="asParent" @update="getPlan">Plan</plan-table>
                          </div>
                        </div>

                        <div class="row document-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showDocument" v-on:click="getDocument">Отгрузочные документы</span>
                            <document-table v-if="showDocument" @element="hideElement('document')" :header="'Отгрузочные документы'"
                                            :downloading="downloading.document"
                                            :header-title="'Отгрузочные документы по заказу'"
                                            :objects="documents" :parent="asParent" >Documents out</document-table>
                          </div>
                        </div>

                        <div class="row production-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showProduction" v-on:click="getProduction()">Выпуск продукции</span>
                            <productions-table v-if="showProduction" @element="hideElement('production')"
                                               :downloading="downloading.production"
                                               :header="'Выпуск продукции'"
                                               :header-title="'Выпуск продукции по заказу'"
                                               :objects="productions" :parent="asParent">Stocks</productions-table>
                          </div>
                        </div>

                        <div class="row plan-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showPlanLeft" v-on:click="getPlanLeft()">К отгрузке</span>
                            <plan-left-table v-if="showPlanLeft" @element="hideElement('planLeft')" :header="'К отгрузке'"
                                             :downloading="downloading.plansLeft"
                                             :header-title="'К отгрузке по заказу '+ object.name"
                                             :objects="plansLeft" :parent="asParent">Documents out</plan-left-table>
                          </div>
                        </div>

                        <div class="row reserve-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showReserve" v-on:click="getReserve()">Резервы под заказ</span>
                            <reserve-table v-if="showReserve" @element="hideElement('reserve')" :header="'Резервы под заказ'"
                                           :downloading="downloading.reserve"
                                           :header-title="'Зарезервированные под выполнение заказа материалы и комплектующие'"
                                           :objects="reserves" :parent="asParent" :group-by="'cell'">Reserves</reserve-table>
                          </div>
                        </div>

                        <div class="row reserve_need-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showReserve_need" v-on:click="getReserve_need()">Необходимые резервы под заказ</span>
                            <reserve-need-table v-if="showReserve_need" @element="hideElement('reserve_need')" :header="'Необходимые резервы под заказ'"
                                                :downloading="downloading.reserveNeed"
                                                :header-title="'Список необходимых для выполнения заказа материалы и комплектующие'"
                                                :objects="reserve_need" :parent="asParent">Reserves</reserve-need-table>
                          </div>
                        </div>

                        <div class="row enquire-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showEnquire" v-on:click="getEnquire">Заявки</span>
                            <enquiries-table v-if="showEnquire" @element="hideElement('enquire')" :header="'Заявки'"
                                             :downloading="downloading.enquiry"
                                             :header-title="'Заявки испоняемые в закупке'"
                                             :objects="enquires" :parent="asParent">Enquiries</enquiries-table>
                          </div>
                        </div>

                        <div class="row lnk-bcg margin-5">
                          <div class="col-12 unselectable">
                            <span v-if="!showLnk" v-on:click="getLnk(true)">Файлы</span>
                            <lnk-table v-if="showLnk" @element="hideElement" :header="'Файлы'"
                                       :downloading="downloading.lnk"
                                       :header-title="'Связные файлы и изображения'"
                                       :objects="lnks" :parent="asParent">Lnk</lnk-table>
                          </div>
                        </div>

                      </div>

                      <event-table v-if="irc.history.show && irc.history.data"
                                   :objects="irc.history.data" :downloading="downloading.history"></event-table>

                      <div class="row" style="padding-top: 20px">
                        <div class="col-md-12">
                          <button v-if="showGeneratorButton" class="btn btn-info btn-fill btn-usm float-right margin-right-icon margin-top-bottom" v-on:click="autoGeneratorOn">
                            Формирование резервов и заявок
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr v-if="showTree && plans && plans.length > 0" v-for="(object, index) in plans" :key="index">
          <td colspan="1">
          </td>
          <td colspan="39">
            <plan :start_object="object" :start_state="'active'" :default_state="'active'"
                  :cs="39" :lastActive="index === plans.length - 1"></plan>
          </td>
        </tr>
      </table>
    </div>

    <table v-if="state === 'full' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="row full-width full-title">
            <div class="col-12">
              <full-view-header :header="'Заказ'" :select="select"
                                :historical="true"
                                @history="irc.controlHistory()"
                                @regularState="state = default_state ? default_state : start_state"
                                @editState="irc.startEdit()" @select="selectObject">
                Header
              </full-view-header>

              <row :header="'Описание заказа:'" :value="object.name">Description</row>

              <row :header="'Основание:'" :value="object.basis">Basis</row>

              <row :header="'Статус заказа:'" :value="object.ordersStatus ? object.ordersStatus.name : '-'">Basis</row>

              <div class="row company-bcg margin-for-inner-obj">
                <company :start_object="object.contact" :header="'Поставщик'"
                         :start_state="'text'" :default_state="'text'"></company>
              </div>
              <div class="row stock-bcg margin-for-inner-obj">
                <stock :start_object="object.consumer" :header="'Заказчик'"
                       :start_state="'text'" :default_state="'text'"></stock>
              </div>

              <div v-if="!select">

                <div class="row plan-bcg margin-5">
                  <div class="col-12 unselectable">
                    <plan-table @element="hideElement('plan')" :header="'Состав заказа'"
                                :downloading="downloading.plan"
                                :header-title="'Номенклатура к отгрузке по заказу'"
                                :objects="plans" :parent="asParent" @update="getPlan">Plan</plan-table>
                  </div>
                </div>

                <div class="row document-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showDocument" v-on:click="getDocument()">Отгрузочные документы</span>
                    <document-table v-if="showDocument" @element="hideElement('document')" :header="'Отгрузочные документы'"
                                    :downloading="downloading.document"
                                    :header-title="'Отгрузочные документы по заказу'"
                                    :objects="documents" :parent="asParent" >Documents out</document-table>
                  </div>
                </div>

                <div class="row production-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showProduction" v-on:click="getProduction()">Выпуск продукции</span>
                    <productions-table v-if="showProduction" @element="hideElement('production')"
                                       :downloading="downloading.production"
                                       :header="'Выпуск продукции'"
                                       :header-title="'Выпуск продукции по заказу'"
                                       :objects="productions" :parent="asParent">Stocks</productions-table>
                  </div>
                </div>

                <div class="row plan-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showPlanLeft" v-on:click="getPlanLeft()">К отгрузке</span>
                    <plan-left-table v-if="showPlanLeft" @element="hideElement('planLeft')" :header="'К отгрузке'"
                                     :downloading="downloading.plansLeft"
                                     :header-title="'К отгрузке по заказу '+ object.name"
                                     :objects="plansLeft" :parent="asParent">Documents out</plan-left-table>
                  </div>
                </div>

                <div class="row reserve-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showReserve" v-on:click="getReserve()">Резервы под заказ</span>
                    <reserve-table v-if="showReserve" @element="hideElement('reserve')" :header="'Резервы под заказ'"
                                   :downloading="downloading.reserve"
                                   :header-title="'Зарезервированные под выполнение заказа материалы и комплектующие'"
                                   :objects="reserves" :parent="asParent" :group-by="'cell'">Reserves</reserve-table>
                  </div>
                </div>

                <div class="row reserve_need-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showReserve_need" v-on:click="getReserve_need()">Необходимые резервы под заказ</span>
                    <reserve-need-table v-if="showReserve_need" @element="hideElement('reserve_need')" :header="'Необходимые резервы под заказ'"
                                        :downloading="downloading.reserveNeed"
                                        :header-title="'Список необходимых для выполнения заказа материалы и комплектующие'"
                                        :objects="reserve_need" :parent="asParent">Reserves</reserve-need-table>
                  </div>
                </div>

                <div class="row enquire-bcg margin-5">
                  <div class="col-12 unselectable">
                    <span v-if="!showEnquire" v-on:click="getEnquire">Заявки</span>
                    <enquiries-table v-if="showEnquire" @element="hideElement('enquire')" :header="'Заявки'"
                                     :downloading="downloading.enquiry"
                                     :header-title="'Заявки испоняемые в закупке'"
                                     :objects="enquires" :parent="asParent">Enquiries</enquiries-table>
                  </div>
                </div>

              </div>

              <event-table v-if="irc.history.show && irc.history.data"
                           :objects="irc.history.data" :downloading="downloading.history"></event-table>

              <div class="row" style="padding-top: 20px">
                <div class="col-md-12">
                  <button v-if="showGeneratorButton" class="btn btn-info btn-fill btn-usm float-right margin-right-icon" v-on:click="autoGeneratorOn">
                    Формирование резервов и заявок
                  </button>
                </div>
              </div>

            </div>
          </div>
        </td>
      </tr>
    </table>

    <table v-if="state === 'edit' && object" class="full-width">
      <tr>
        <td class="full-width">
          <div class="full-width">
            <edit-header @fullView="fullView" @save="save">header</edit-header>

            <input-row v-model="object.name" :header="'Описание заказа:'" class="full-width"></input-row>

            <div class="row margin-for-inner-obj" v-if="object.id === 0 && (!parent || parent.type !== 'contact')">
              <company :start_object="object.contact" :start_state="'text'" :default_state="'text'"
                       :select="true" @select="setContact" :header="'Исполнитель:'"
                       :select-bounds="getBounds()"> Contact </company>
            </div>

            <div class="row margin-for-inner-obj" v-if="object.id === 0 && (!parent || parent.type !== 'stock')">
              <stock :start_object="object.consumer" :start_state="'text'" :default_state="'text'"
                     :select="true" @select="setConsumer" :header="'Заказчик:'"
                     :select-bounds="getBounds()"> Consumer </stock>
            </div>
            <div class="row margin-for-inner-obj">
              <order-status :start_object="object.orderStatus" :start_state="'text'" :default_state="'text'"
                            :select="true" @select="setStatus" :header="'Статус заказа:'"> Order status </order-status>
            </div>
            <edit-bottom @fullView="fullView" @save="save">bottom</edit-bottom>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="showSelect" :id="id" class="full-width">
      <order-table v-bind:objects="selectPool" v-bind:header="'Заказы'" @select="selectObjectFromTable"
                   :downloading="downloading.select"
                   @closeModal="closeModal"
                   v-bind:header-title="'Список заказов'" :select="select"></order-table>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import {
  ENQUIRE,
  ORDER,
  PLAN,
  PRODUCTION,
  RESERVE,
  DOCUMENT,
  warning,
  ITEM,
  PORTION,
  LNK
} from "@/repository/repository";
import {ElementInterface} from "@/models/elementInteface";
import {asDate} from "@/services";

export default {
  components: {
    Table
  },
  computed: {
    asDate: function () {
      if (this.object.date !== undefined) {
        let date = new Date(this.object.date);
        return date.customFormat('#DD#/#MM#/#YYYY#');
      } else {
        return '-'
      }
    },
  },
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      plans: [],
      showPlan: false,
      reserves: [],
      showReserve: false,
      reserve_need: [],
      showReserve_need: false,
      plansLeft: [],
      showPlanLeft: false,
      documents: [],
      showDocument: false,
      productions: [],
      showProduction: false,
      enquires: [],
      showEnquire: false,
      lnks: [],
      showLnk: false,
      id: null,
      selectPool: [],
      asParent: {},
      showGeneratorButton: true,
      viewKey: 0,
      flag: this.$flag,
      activeState: 'row',
      showTree: false,
      irc: null,
      showSelect: false,
      downloading: {state: false, select: {state: false}, plan: {state: false}, reserve: {state: false}, reserveNeed: {state: false}, plansLeft: {state: false},
        document: {state: false}, production: {state: false}, enquiry: {state: false}, lnk: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    dispatcher() {
      if (this.select) {
        this.openSelectModal();
      } else {
        this.fullView()
      }
    },
    toDate(date) {
      return asDate(date);
    },
    async fullView() {
      if (this.object.id > 0) {
        this.object = await this.$repo.getSingle('order/' + this.object.id, ORDER, this.downloading);
        this.state = 'full'
        this.$emit("update_state")
      } else {
        this.$emit('close', true);
      }
    },
    async getPlan() {
      this.plans = [];
      await this.$repo.getList('order/plan/' + this.object.id, PLAN, this.plans, null, this.downloading.plan);
    },
    async getReserve() {
      this.reserves = [];
      this.showReserve = true;
      await this.$repo.getList('order/reserve/' + this.object.id, RESERVE, this.reserves, null, this.downloading.reserve);
    },
    async getReserve_need() {
      this.reserve_need = [];
      this.showReserve_need = true;
      await this.$repo.justGetList('order/reserveNeed/' + this.object.id, this.reserve_need, null, this.downloading.reserveNeed);
    },
    async getPlanLeft() {
      this.plansLeft = [];
      this.showPlanLeft = true;
      await this.$repo.justGetList('order/planLeft/' + this.object.id, this.plansLeft, null, this.downloading.plansLeft);
    },
    async getDocument() {
      this.documents = [];
      this.showDocument = true;
      await this.$repo.getList('order/document/' + this.object.id, DOCUMENT, this.documents, null, this.downloading.document);
    },
    async getEnquire() {
      this.enquires = [];
      this.showEnquire = true;
      await this.$repo.getList('order/enquire/' + this.object.id, ENQUIRE, this.enquires, null, this.downloading.enquiry);
    },
    async getProduction() {
      this.productions = [];
      this.showProduction = true;
      await this.$repo.getList('order/production/' + this.object.id, PRODUCTION, this.productions, null, this.downloading.production);
    },
    async getLnk(show) {
      this.lnks = [];
      this.showLnk = true;
      await this.$repo.getList('order/lnk/' + this.object.id, LNK, this.lnks, null, this.downloading.lnk);
    },
    getBounds() {
      return undefined;
    },
    hideElement(element) {
      if (element === RESERVE) {
        this.showReserve = false;
        this.reserves = [];
      } else if (element === 'planLeft') {
        this.showPlanLeft = false;
        this.plansLeft = [];
      } else if (element === 'reserve_need') {
        this.showReserve_need = false;
        this.reserve_need = [];
      } else if (element === DOCUMENT) {
        this.showDocument = false;
        this.documents = [];
      } else if (element === PRODUCTION) {
        this.showProduction = false;
        this.productions = [];
      } else if (element === PLAN) {
        this.showPlan = false;
        this.plans = [];
      } else if (element === ENQUIRE) {
        this.showEnquire = false;
        this.enquires = [];
      } else if (element === LNK) {
        this.showLnk = false;
        this.lnks = [];
      }
    },
    updateState() {
      this.viewKey++;
    },
    getName(obj) {
      if (obj !== undefined) {
        return obj.name;
      } else {
        return '-';
      }
    },
    setContact(contact) {
      this.object.contact = contact;
    },
    setConsumer(stock) {
      this.object.consumer = stock;
    },
    setStatus(status) {
      this.object.ordersStatus = status;
    },
    save() {
      let url = 'order/';
      if (this.parent && this.object.id === 0) {
        url = url + '?type=' + this.parent.type + '&id=' + this.parent.id;
      }
      this.$repo.uniSave(this, ORDER, url, this.downloading);
    },
    async autoGeneratorOn() {
      this.showGeneratorButton = false;
      await this.$repo.justGetData('order/process/' + this.object.id, this.downloading);
      this.showGeneratorButton = true;
    },
    selectObject() {
      this.$emit('select', this.object);
    },
    selectObjectFromTable(object) {
      this.closeModal();
      this.object = object;
      this.selectObject();
    },
    setImage(image) {
      this.object.image = image;
    },
    callModal() {
      this.showSelect = true;
    },
    closeModal() {
      this.showSelect = false;
      this.selectPool = [];
    },
    async openSelectModal() {
      this.selectPool = [];
      if (this.parent && !this.selectBounds) {
        this.selectBounds = [{includes: true, type: this.parent.type, id: this.parent.id}];
      }
      await this.$repo.getSelectPoll(ORDER, this.selectBounds, this.selectPool, this.downloading.select);
      this.callModal();
    },
    giveData(type) {
      if (type === 'lnk') {
        this.getLnk(false);
      }
    },
    valToDate(val) {
      console.log(val)
      return asDate(val);
    }
  },
  mounted: async function () {
    this.irc = new ElementInterface(this, ORDER, this.downloading.history);
    this.state = this.start_state;
    this.object = this.start_object && this.start_object.id && this.start_object.id > 0 ? await this.$repo.get(ORDER, [this.start_object.id], this.downloading) : this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
    if (this.state === 'active') {
      await this.getPlan();
    }
  },
  watch: {
    object: function () {
      this.asParent = {type: ORDER, id: this.object ? this.object.id : this.start_object.id}
    },
    state: async function (value) {
      await this.$repo.watchState(this, ORDER, value);
      if (value === 'full') {
        await this.getPlan();
      }
    },
    'flag.value': function (val) {
      this.$repo.watchFlag(this, ORDER, val);
    }
  }
}
</script>
<style>
</style>
