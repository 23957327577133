<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link v-if="menu.item" to="/ruler/item">
        <i class="nc-icon nc-layers-3"></i>
        <p>Номенклатура </p>
        <p v-if="messages && messages.mapping && messages.mapping.item && messages.mapping.item.counter > 0" style="color: #ef7676; padding-left: 5px">
          {{messages.mapping.item.counter}}
        </p>
      </sidebar-link>
      <sidebar-link v-if="menu.salesman" to="/ruler/salesman">
        <i class="nc-icon nc-layers-3"></i>
        <p>Сводные данные</p>
      </sidebar-link>
      <sidebar-link v-if="menu.company" to="/ruler/company">
        <i class="nc-icon nc-planet"></i>
        <p>Компании</p>
      </sidebar-link>
      <sidebar-link v-if="menu.department" to="/ruler/department">
        <i class="nc-icon nc-bank"></i>
        <p>Департаменты</p>
      </sidebar-link>
      <sidebar-link v-if="menu.user" to="/ruler/transactions">
        <i class="nc-icon nc-money-coins"></i>
        <p>Платежи</p>
      </sidebar-link>
      <sidebar-link v-if="menu.invoice" to="/ruler/invoices">
        <i class="nc-icon nc-paper-2"></i>
        <p>Счета</p>
      </sidebar-link>
      <sidebar-link v-if="menu.quotation" to="/ruler/quotations">
        <i class="nc-icon nc-atom"></i>
        <p>Предложения</p>
      </sidebar-link>
      <sidebar-link v-if="menu.specification" to="/ruler/specifications">
        <i class="nc-icon nc-single-copy-04"></i>
        <p>Спецификации</p>
      </sidebar-link>
      <sidebar-link v-if="menu.order" to="/ruler/orders">
        <i class="nc-icon nc-notes"></i>
        <p>Заказы</p>
        <p v-if="messages && messages.mapping && messages.mapping.order && messages.mapping.order.counter > 0" style="color: #ef7676; padding-left: 5px">
          {{messages.mapping.item.counter}}
        </p>
      </sidebar-link>
      <sidebar-link v-if="menu.toSupply" to="/ruler/plans_left">
        <i class="nc-icon nc-time-alarm"></i>
        <p>К отгрузке</p>
      </sidebar-link>
      <sidebar-link v-if="menu.itemCells" to="/ruler/item_cells">
        <i class="nc-icon nc-chart-bar-32"></i>
        <p>Склад</p>
      </sidebar-link>

      <sidebar-link v-if="menu.enquire" to="/ruler/enquiries">
        <i class="nc-icon nc-send"></i>
        <p>Заявки на закупку</p>
      </sidebar-link>

      <sidebar-link v-if="menu.purchase" to="/ruler/purchases">
        <i class="nc-icon nc-delivery-fast"></i>
        <p>Закупки</p>
      </sidebar-link>

      <sidebar-link v-if="menu.stockman" to="/ruler/stockman">
        <i class="nc-icon nc-layers-3"></i>
        <p>Склад</p>
      </sidebar-link>

      <sidebar-link to="/ruler/moves">
        <i class="nc-icon nc-bus-front-12"></i>
        <p>Перемещения</p>
      </sidebar-link>

      <sidebar-link v-if="menu.order" to="/ruler/reports">
        <i class="nc-icon nc-grid-45"></i>
        <p>Отчеты</p>
      </sidebar-link>

      <sidebar-link v-if="menu.workTime" to="/ruler/work_times">
        <i class="nc-icon nc-watch-time"></i>
        <p>Учет времени</p>
      </sidebar-link>
      <sidebar-link v-if="menu.employee" to="/ruler/employees">
        <i class="nc-icon nc-circle-09"></i>
        <p>Сотрудники</p>
      </sidebar-link>
      <sidebar-link v-if="menu.equipment" to="/ruler/equipments">
        <i class="nc-icon nc-spaceship"></i>
        <p>Оборудование</p>
      </sidebar-link>
      <sidebar-link v-if="menu.order" to="/ruler/certificates_data">
        <i class="nc-icon nc-tag-content"></i>
        <p>Паспорта качества</p>
      </sidebar-link>
      <br/>
      <sidebar-link to="/ruler/crm">
        <i class="nc-icon nc-android"></i>
        <p>CRM</p>
      </sidebar-link>

      <br/>
      <sidebar-link v-if="menu.user" to="/ruler/users">
        <i class="nc-icon nc-android"></i>
        <p>Пользователи</p>
      </sidebar-link>

      <br/>

      <sidebar-link v-if="menu.user" to="/ruler/icons">
        <i class="nc-icon nc-android"></i>
        <p>Иконки</p>
      </sidebar-link>

      <div v-if="menu.user">
        {{ `socket state: ${sock.state}` }}
      </div>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  import {socketState} from "@/repository/dataUpdater";
  import {MESSAGE, NOTE, SERVICE_STORE} from "@/repository/repository";
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu
    },
    data () {
      return {
        menu: {},
        tt: null,
        sock: 0,
        messages: {},
        downloading: {messages: {state: false}},
      }
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    async mounted() {
      this.menu = await this.$repo.justGetData('menu/');
      if (!this.menu.item && this.menu.stockman && this.$router.currentRoute.name !== 'Stockman') {
        await this.$router.push({name: 'Stockman'})
      }
      this.sock = socketState;
      let tempMsgList = [];
      await this.$repo.justGetList('msg/', tempMsgList, null, this.downloading.messages);
      this.messages = this.$repo.getMsgStore();
      tempMsgList.forEach(msg => {
        this.messages.addMsg(msg);
      })
    }
  }

</script>
