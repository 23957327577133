<template>
  <div :key="viewKey" class="full-width">

    <downloading v-if="downloading && downloading.state" :single="true" :downloading="downloading">
      Downloading
    </downloading>

    <div v-if="state === 'move' && object && object.id && object.cell" class="row row-title">
      <div class="col-2">
        Заведено
      </div>
      <div class="col-2">
        {{ asDate(object.date) }}
      </div>
      <div class="col-6">
        {{ object.cell.item.name }}
      </div>
      <div class="col-2">
        {{ asValue(object.value) + ' ' + object.cell.item.measure}}
      </div>
    </div>

  </div>
</template>
<script>

import {authHeader} from "@/auth";
import Table from "@/components";
import CompaniesTable from "@/tables/CompaniesTable";
import {INCOME, ITEM, PLAN, RESERVE} from "@/repository/repository";
import {asDate} from "@/services";

export default {
  data () {
    return {
      key: authHeader(),
      state: 'row',
      object: {},
      id: null,
      selectPool: [],
      asParent: {},
      viewKey: 0,
      flag: this.$flag,
      showSelect: false,
      downloading: {state: false, select: {state: false}, history: {state: false}},
    }
  },
  props: {
    start_object: {},
    start_state: '',
    default_state: null,
    select: false,
    selectBounds: null,
    parent: null,
    header: null,
  },
  methods: {
    asDate: function (val) {
      return asDate(val ? val : this.object.date);
    },
    asValue(data) {
      return data % 1 === 0 ? data : (Math.round(data * 1000) / 1000).toFixed(3);
    },
  },
  mounted: async function () {
    this.state = this.start_state;
    this.object = this.start_object;
    this.id = this._uid;
    if (!this.default_state){
      this.default_state = this.start_state;
    }
  },
  watch: {
  }
}
</script>
<style>
</style>
