import BaseInput from './components/Inputs/BaseInput.vue'
import BaseCheckbox from './components/Inputs/BaseCheckbox.vue'
import BaseRadio from './components/Inputs/BaseRadio.vue'
import BaseDropdown from './components/BaseDropdown.vue'
import Card from './components/Cards/Card.vue'
import Row from "@/components/Row";
import InputRow from "@/components/InputRow";
import ItemsTable from "./tables/ItemsTable";
import Item from "./elements/Item";
import ItemCategory from "@/filters/ItemCategory";
import LnkTable from "@/tables/LnkTable";
import Lnk from "./elements/Lnk";
import NoteTable from "@/tables/NoteTable";
import Note from "./elements/Note";
import CompanyTable from "@/tables/CompaniesTable";
import Company from "./elements/Company";
import StockTable from "@/tables/StocksTable";
import Stock from "@/elements/Stock";
import DocumentTable from "@/tables/DocumentsTable";
import Document from "@/elements/Document";
import OrdersTable from "@/tables/OrdersTable";
import Order from "@/elements/Order";
import OrderStatusTable from "@/tables/OrderStatusTable";
import OrderStatus from "@/elements/OrderStatus";
import StockCell from "@/elements/StockCell";
import StockCellsTable from "@/tables/StockCellsTable";
import Reserve from "@/elements/Reserve";
import ReservesTable from "@/tables/ReservesTable";
import ReserveNeed from "@/elements/ReserveNeed";
import ReserveNeedTable from "@/tables/ReserveNeedTable";
import Plan from "@/elements/Plan";
import PlansTable from "@/tables/PlansTable";
import PlanLeft from "@/elements/PlanLeft";
import PlansLeftTable from "@/tables/PlansLeftTable";
import Portion from "@/elements/Portion";
import PortionsTable from "@/tables/PortionsTable";
import Production from "@/elements/Production";
import ProductionsTable from "@/tables/ProductionsTable";
import Res from "@/elements/Res";
import ResTable from "@/tables/ResTable";
import DateWarning from "@/components/DateWarning";
import ItemsCellsTable from "@/tables/ItemsCellsTable";
import ItemCell from "@/elements/ItemCell";
import ValueWarning from "@/components/ValueWarning";
import Employee from "@/elements/Employee";
import EmployeesTable from "@/tables/EmployeesTable";
import EquipmentsTable from "@/tables/EquipmentsTable";
import Equipment from "@/elements/Equipment";
import WorkTime from "@/elements/WorkTime";
import WorkTimesTable from "@/tables/WorkTimesTable";
import User from "@/elements/User";
import UsersTable from "@/tables/UsersTable";
import Assembly from "@/elements/Assembly";
import AssembliesTable from "@/tables/AssembliesTable";
import Enquire from "@/elements/Enquire";
import EnquiriesTable from "@/tables/EnquiriesTable";
import Purchase from "@/elements/Purchase";
import PurchasesTable from "@/tables/PurchasesTable";
import Receipt from "@/elements/Receipt";
import ReceiptsTable from "@/tables/ReceiptsTable";
import EditHeader from "@/components/EditHeader";
import FullViewHeader from "@/components/FullViewHeader";
import EditBottom from "@/components/Inputs/EditBottom";
import SupplierRequest from "@/elements/SupplierRequest";
import SupplierRequestTable from "@/tables/SupplierRequestTable";
import ActiveInput from "@/components/ActiveInput";
import ActiveInputElement from "@/components/ActiveInputElement";
import ItemCategoryTable from "@/tables/ItemCategoryTable";
import ItemCategorySelect from "@/selectable/ItemCategorySelect";
import Sign from "@/elements/Sign";
import SignsTable from "@/tables/SignsTable";
import Invoice from "@/elements/Invoice";
import InvoicesTable from "@/tables/InvoicesTable";
import WarningModal from "@/components/WarningModal";
import StockManTable from "@/tables/StockManTable";
import StockManCell from "@/elements/StockManCell";
import SelectorTable from "@/tables/SelectorTable";
import SelectorTableNameOnly from "@/tables/SelectorTableNameOnly";
import SaleManTable from "@/tables/SaleManTable";
import ReserveGrouped from "@/elements/ReserveGrouped";
import AssembliesActiveTable from "@/tables/AssembliesActiveTable";
import RowUp from "@/components/RowUp";
import InputRowUp from "@/components/InputRowUp";
import SMD from "@/elements/SMD";
import CertificteCounterTable from "@/tables/CertificteCounterTable";
import CertificateCounter from "@/elements/CertificateCounter";
import CertificatePattern from "@/elements/CertificatePattern";
import CertifictePatternTable from "@/tables/CertifictePatternTable";
import Certificate from "@/elements/Certificate";
import colorPicker from "@/components/ColorPicker";
import CRM from "@/pages/CRM";
import Quotation from "@/elements/Quotation";
import QuotationTable from "@/tables/QuotationTable";
import QuotationElement from "@/elements/QuotationElement";
import QuotationElementTable from "@/tables/QuotationElementTable";
import ItemSaleHistory from "@/elements/ItemSaleHistory";
import ItemSaleHistoryTable from "@/tables/ItemSaleHistoryTable";
import PlansCommercialTable from "@/tables/PlansCommercialTable";
import EventTable from "@/tables/EventTable";
import Downloading from "@/components/Downloading";
import Account from "@/elements/Account";
import AccountsTable from "@/tables/AccountsTable";
import Transaction from "@/elements/Transaction";
import TransactionsTable from "@/tables/TransactionsTable";
import Checker from "@/components/Checker";
import Specification from "@/elements/Specification";
import SpecificationsTable from "@/tables/SpecificationsTable";
import Moves from "@/pages/Moves";
import MovesTable from "@/tables/MovesTable";
import Income from "@/elements/Income";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component('crm', CRM)
    Vue.component('color-picker', colorPicker)
    Vue.component('row', Row)
    Vue.component('input-row', InputRow)
    Vue.component('card', Card)
    Vue.component('date-warning', DateWarning)
    Vue.component('value-warning', ValueWarning)
    Vue.component('item', Item)
    Vue.component('items-table', ItemsTable)
    Vue.component('lnk', Lnk)
    Vue.component('lnk-table', LnkTable)
    Vue.component('note', Note)
    Vue.component('note-table', NoteTable)
    Vue.component('company', Company)
    Vue.component('company-table', CompanyTable)
    Vue.component('stock', Stock)
    Vue.component('stock-table', StockTable)
    Vue.component('document', Document)
    Vue.component('document-table', DocumentTable)
    Vue.component('order', Order)
    Vue.component('order-table', OrdersTable)
    Vue.component('order-status', OrderStatus)
    Vue.component('order-status-table', OrderStatusTable)
    Vue.component('stock-cell', StockCell)
    Vue.component('stock-cell-table', StockCellsTable)
    Vue.component('reserve', Reserve)
    Vue.component('reserve-table', ReservesTable)
    Vue.component('reserve-need', ReserveNeed)
    Vue.component('reserve-need-table', ReserveNeedTable)
    Vue.component('plan', Plan)
    Vue.component('plan-table', PlansTable)
    Vue.component('plan-left', PlanLeft)
    Vue.component('plan-left-table', PlansLeftTable)
    Vue.component('portion', Portion)
    Vue.component('portion-table', PortionsTable)
    Vue.component('item-category', ItemCategory)
    Vue.component('production', Production)
    Vue.component('productions-table', ProductionsTable)
    Vue.component('res', Res)
    Vue.component('res-table', ResTable)
    Vue.component('item-cell', ItemCell)
    Vue.component('item-cell-table', ItemsCellsTable)
    Vue.component('employees-table', EmployeesTable)
    Vue.component('employee', Employee)
    Vue.component('equipments-table', EquipmentsTable)
    Vue.component('equipment', Equipment)
    Vue.component('work-time', WorkTime)
    Vue.component('work-times-table', WorkTimesTable)
    Vue.component('user', User)
    Vue.component('users-table', UsersTable)
    Vue.component('assembly', Assembly)
    Vue.component('assemblies-table', AssembliesTable)
    Vue.component('enquire', Enquire)
    Vue.component('enquiries-table', EnquiriesTable)
    Vue.component('purchase', Purchase)
    Vue.component('purchases-table', PurchasesTable)
    Vue.component('receipt', Receipt)
    Vue.component('receipts-table', ReceiptsTable)
    Vue.component('edit-header', EditHeader)
    Vue.component('full-view-header', FullViewHeader)
    Vue.component('edit-bottom', EditBottom)
    Vue.component('supplier-request', SupplierRequest)
    Vue.component('supplier-request-table', SupplierRequestTable)
    Vue.component('active-input', ActiveInput)
    Vue.component('active-input-element', ActiveInputElement)
    Vue.component('item-category-table', ItemCategoryTable)
    Vue.component('item-category-select', ItemCategorySelect)
    Vue.component('sign', Sign)
    Vue.component('sign-table', SignsTable)
    Vue.component('invoice', Invoice)
    Vue.component('invoices-table', InvoicesTable)
    Vue.component('warning-modal', WarningModal)
    Vue.component('stock-man-table', StockManTable)
    Vue.component('stock-man-cell', StockManCell)
    Vue.component('selector-table', SelectorTable)
    Vue.component('selector-table-name-only', SelectorTableNameOnly)
    Vue.component('sale-man-table', SaleManTable)
    Vue.component('smd', SMD)
    Vue.component('reserve-grouped', ReserveGrouped)
    Vue.component('assemblies-active-table', AssembliesActiveTable)
    Vue.component('row-up', RowUp)
    Vue.component('input-row-up', InputRowUp)
    Vue.component('certificate-counter-table', CertificteCounterTable)
    Vue.component('certificate-counter', CertificateCounter)
    Vue.component('certificate-pattern', CertificatePattern)
    Vue.component('certificate-pattern-table', CertifictePatternTable)
    Vue.component('certificate', Certificate)
    Vue.component('quotation', Quotation)
    Vue.component('quotation-table', QuotationTable)
    Vue.component('quotation-element', QuotationElement)
    Vue.component('quotation-element-table', QuotationElementTable)
    Vue.component('item-sale-history', ItemSaleHistory)
    Vue.component('item-sale-history-table', ItemSaleHistoryTable)
    Vue.component('plans-commercial-table', PlansCommercialTable)
    Vue.component('event-table', EventTable)
    Vue.component('downloading', Downloading)
    Vue.component('account', Account)
    Vue.component('accounts-table', AccountsTable)
    Vue.component('transaction', Transaction)
    Vue.component('transactions-table', TransactionsTable)
    Vue.component('checker', Checker)
    Vue.component('specification', Specification)
    Vue.component('specifications-table', SpecificationsTable)
    Vue.component('moves', Moves)
    Vue.component('moves-table', MovesTable)
    Vue.component('income', Income)

  }
}

export default GlobalComponents
